import React, { useRef, useState } from "react";
import { Row, Col, Form, Button, Table, Input, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useReactToPrint } from "react-to-print";
import { GET_EXP_SHIPMENTS_STATUS } from "../../../config/webTrackApi";
import moment from "moment";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { checkinUser, userAwbLogout } from "../../../actions/userAWB";
import { setPaymentInfo } from "../../../actions/payment";

const TabAirlinesExpStatus = () => {
  const { t } = useTranslation("common");
  const [form] = useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [headerTable, setHeaderTable] = useState();
  const [dataSource, setDataSource] = useState();
  const [loading, setLoading] = useState(false);
  const { dataLogin } = useSelector((state) => state.userAwb);
  const columns = [
    {
      title: t("CutOffTime.no"),
      dataIndex: "Index",
      key: "Index",
      className: "head_tb_repo",
      render: (_, record, index) => index + 1,
    },
    {
      title: t("agentReport.MAWB"),
      dataIndex: "MAWB_NO",
      key: "MAWB_NO",
      className: "head_tb_repo",
    },
    {
      title: t("agentReport.fightNo"),
      dataIndex: "parent",
      key: "parent",
      className: "head_tb_repo",
      children: [
        {
          title: t("agentReport.no"),
          dataIndex: "BK_FL_NO",
          key: "BK_FL_NO",
          className: "head_tb_repo",
        },
        {
          title: t("agentReport.Date"),
          dataIndex: "BK_FL_DATE",
          key: "BK_FL_DATE",
          className: "head_tb_repo",
          render: (text) =>
            moment(text).isValid() && moment(text).format("DD/MM/YYYY"),
        },
      ],
    },
    {
      title: "DES",
      dataIndex: "DES",
      key: "DES",
      className: "head_tb_repo",
    },
    {
      title: t("agentReport.Pieces"),
      dataIndex: "PCS",
      key: "PCS",
      className: "head_tb_repo",
    },
    {
      title: t("agentReport.GrossWeight"),
      dataIndex: "parent",
      key: "parent",
      className: "head_tb_repo",
      children: [
        {
          title: t("agentReport.Actual"),
          dataIndex: "WEIGHT",
          key: "WEIGHT",
          className: "head_tb_repo",
        },
        {
          title: t("agentReport.change"),
          dataIndex: "VOL_WGT",
          key: "VOL_WGT",
          className: "head_tb_repo",
        },
      ],
    },
    {
      title: t("Charge weight"),
      dataIndex: "CHG_WGT",
      key: "CHG_WGT",
      className: "head_tb_repo",
    },
    {
      title: t("agentReport.volume"),
      dataIndex: "PRE_MNF_SHP_VOL",
      key: "PRE_MNF_SHP_VOL",
      className: "head_tb_repo",
    },
    {
      title: "SHC",
      dataIndex: "SHC",
      key: "SHC",
      className: "head_tb_repo",
    },
    {
      title: "ULD",
      dataIndex: "parent",
      key: "parent",
      className: "head_tb_repo",
      children: [
        {
          title: t("agentReport.no"),
          dataIndex: "ULD_NO",
          key: "ULD_NO",
          className: "head_tb_repo",
        },
        {
          title: t("agentReport.NatureGoods"),
          dataIndex: "ULD_TYPE",
          key: "ULD_TYPE",
          className: "head_tb_repo",
        },
        {
          title: t("agentReport.Note"),
          dataIndex: "ULD_REMARK",
          key: "ULD_REMARK",
          className: "head_tb_repo",
        },
      ],
    },
    {
      title: t("agentReport.put"),
      dataIndex: "parent",
      key: "parent",
      className: "head_tb_repo",
      children: [
        {
          title: t("agentReport.Pieces"),
          dataIndex: "LOADING_PCS",
          key: "LOADING_PCS",
          className: "head_tb_repo",
        },
        {
          title: t("agentReport.GrossWeight"),
          dataIndex: "LOADING_WGT",
          key: "LOADING_WGT",
          className: "head_tb_repo",
          render: (text) => (text === "0" ? "" : text),
        },
      ],
    },
    {
      title: t("agentReport.status"),
      dataIndex: "parent",
      key: "parent",
      className: "head_tb_repo",
      children: [
        {
          title: t("agentReport.customs"),
          dataIndex: "STS_CUSTOM_YN",
          key: "STS_CUSTOM_YN",
          className: "head_tb_repo",
        },
      ],
    },
    {
      title: "Pre MNF",
      dataIndex: "parent",
      key: "parent",
      className: "head_tb_repo",
      children: [
        {
          title: t("agentReport.fightNo"),
          dataIndex: "PRE_MNF_FL_NO",
          key: "PRE_MNF_FL_NO",
          className: "head_tb_repo",
        },
        {
          title: t("agentReport.Date"),
          dataIndex: "PRE_MNF_FL_DATE",
          key: "PRE_MNF_FL_DATE",
          className: "head_tb_repo",
          render: (text) =>
            moment(text).isValid() &&
            moment(text).format("DD/MM/YYYY") === "01/01/2001"
              ? ""
              : moment(text).isValid() && moment(text).format("DD/MM/YYYY"),
        },
      ],
    },
  ];

  const submitStatus = async (values) => {
    let { carrierId, flightNumberGoodsOut } = values;
    if (carrierId === undefined)
      carrierId = dataLogin.data.user_code.slice(
        2,
        dataLogin.data.user_code.length
      );

    try {
      const flightDate = moment().format("DD/MM/YYYY");
      setLoading(true);
      let dataRes;
      if (
        dataLogin.data.user_code.includes("admi") ||
        dataLogin.data.user_code.includes(carrierId)
      ) {
        dataRes = await GET_EXP_SHIPMENTS_STATUS(
          carrierId,
          flightNumberGoodsOut,
          flightDate,
          dataLogin.token
        );
      } else {
        notification["error"]({
          message: "INFO",
          description: t("agentReport.role"),
          style: { marginRight: "-50%" },
        });
        return;
      }
      // if(dataLogin.data.user_code === 'admi'){
      //   dataRes = await GET_EXP_SHIPMENTS_STATUS(
      //     carrierId,
      //     flightNumberGoodsOut,
      //     flightDate
      //   );
      // }else if(dataLogin.data.user_code.includes(carrierId)){
      //   dataRes = await GET_EXP_SHIPMENTS_STATUS(
      //     carrierId,
      //     flightNumberGoodsOut,
      //     flightDate
      //   );
      // }
      let data = dataRes && [...dataRes.data.mCol];
      setDataSource(data);
      setHeaderTable(t("agentReport.carrier") + ` ${flightNumberGoodsOut} AIR`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      } else
        notification["error"]({
          message: "INFO",
          description: t("agentReport.notfindData"),
          style: { marginRight: "-50%" },
        });
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const user =
    dataLogin.data.user_code === "admi"
      ? ""
      : dataLogin.data.user_code.slice(2, 4);

  return (
    <div>
      <Row className="awb-tab-title-wrapper">
        <Col span={24} className="awb-tab-title">
          <h5>{t("agentReport.listMAWB")}</h5>
        </Col>
      </Row>
      <Row className="awb-tab-content">
        <Row gutter={[0, 16]} className="control-wrapper">
          <Col style={{ display: "flex", justifyContent: "center" }} span={24}>
            <Form
              className="form-status"
              onFinish={submitStatus}
              layout={"inline"}
              form={form}
              requiredMark="optional"
              initialValues={{
                flightNumberGoodsOut: `${user}`,
              }}
              autoComplete="off"
            >
              <Row style={{ width: "100%" }}>
                <Col
                  style={{ display: "flex", justifyContent: "center" }}
                  span={24}
                >
                  {" "}
                  {dataLogin.data.user_code === "admi" && (
                    <Form.Item
                      labelAlign={"right"}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ offset: 1 }}
                      name="carrierId"
                      label={t("agentReport.DeliveryTranform")}
                      rules={[
                        {
                          required: true,
                          message: t("agentReport.require"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row style={{ width: "100%", marginTop: "5px" }}>
                <Col
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                  span={24}
                >
                  <Form.Item
                    labelCol={{ span: 12 }}
                    labelAlign={"left"}
                    name="flightNumberGoodsOut"
                    rules={[
                      {
                        required: true,
                        message: t("agentReport.require"),
                      },
                    ]}
                    label={t("agentReport.noflight")}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              {/* <Form.Item
                name="flightDate"
                label="Ngày: "
                rules={[{ required: true, message: "Cần nhập" }]}
              >
                <DatePicker className="flight-number" format="DD/MM/YYYY" />
              </Form.Item> */}
              <Row style={{ width: "100%", marginTop: "10px" }}>
                <Col
                  style={{ display: "flex", justifyContent: "center" }}
                  span={24}
                >
                  <Button
                    style={{ width: "fit-content" }}
                    htmlType={"submit"}
                    loading={loading}
                  >
                    {t("airlines.refresh")}
                  </Button>
                  <Button
                    onClick={handlePrint}
                    style={{ width: "fit-content" }}
                    disabled={!dataSource}
                  >
                    {t("airlines.print")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row ref={componentRef} className="result-wrapper">
          <Row className="result-wrapper">
            <Row className="result-title-wrapper">
              <Col span={24} className="result-title">
                <h5>{t("airlines.list")}</h5>
              </Col>
            </Row>
            <Row className="result-wrapper">
              <Col span={24} className="result">
                {dataSource && (
                  <Table
                    className="table-bill font-small"
                    dataSource={dataSource}
                    columns={columns}
                    title={() => headerTable}
                    bordered
                    scroll={{ x: 500 }}
                    pagination={{
                      position: "bottomRight",
                      defaultPageSize: 1000,
                    }}
                    summary={() => (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={18}>
                            {t("agentReport.total")} {dataSource.length}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={18}>
                            {t("agentReport.Time")}
                            <Moment format="DD/MM/YYYY hh-mm">{new Date()}</Moment>
                            {/* {moment().format("DD/MM/YYYY HH:MM:SS")} */}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    )}
                  />
                )}
              </Col>
            </Row>
          </Row>
        </Row>
      </Row>
    </div>
  );
};
export default TabAirlinesExpStatus;
