/* eslint-disable */

import {
  Button,
  Input,
  Form,
  Col,
  Row,
  DatePicker,
  Modal,
  notification,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import {
  IMP_WEB_CHK_CRE_CHK_OBJ,
  IMP_WEB_CHK_INS,
  IMP_WEB_CHK_UPD,
} from "../../config/apiConfig";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import ModalUpdateAgent from "./TabReceiverInfo/ModalUpdateAgent";
import { checkinUser } from "../../actions/userAWB";
import { setPaymentInfo } from "../../actions/payment";
import { userAwbLogout } from "./../../actions/userAWB";
import { useHistory } from "react-router";
import { ModalDatePicker } from "./TabReceiverInfo/ModalDatePicker";
import { convertNumberToTime } from "../../utils/utils";

const { warning, confirm } = Modal;
const TabReceiverInfo = ({
  info,
  isInfoCreated,
  status,
  loadData,
  awbDetail,
  ...props
}) => {
  const [form] = useForm();
  const [formAWB] = useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.userAwb.dataCheckin);
  const { MAWB } = useSelector((state) => state.mawb);
  const [visible, setVisible] = useState(false);
  const [visibleDatePicker, setVisibleDatePicker] = useState(false);

  const showData = () => {
    if (info !== undefined) {
      const {
        DAT_DLV,
        DLV_ODR_RCV_ID,
        DLV_ODR_RCV_NAM,
        EML_RCV_EINV,
        CUS_RMK,
        COD_TYP_SVR,
      } = info;

      form.setFieldsValue({
        receiveDate: moment(DAT_DLV, "YYYY/MM/DD HH:mm"),
        receiverId: DLV_ODR_RCV_ID,
        receiverName: DLV_ODR_RCV_NAM,
        email: EML_RCV_EINV,
        phone: CUS_RMK.slice(0, CUS_RMK.indexOf("-")),
        address: CUS_RMK.slice(CUS_RMK.indexOf("-") + 1, CUS_RMK.length),
        impCat: COD_TYP_SVR === 5 ? "" : COD_TYP_SVR,
      });
    }
    if (awbDetail !== undefined) {
      const {
        COD_QUE_NUM_CHK,
        FLG_IMP_EXP,
        COD_APT_BRD,
        COD_APT_OFF,
        PCS,
        WGT,
        RE_WGT,
        CHG_WGT,
        SHC1,
        SHC2,
        SHC3,
        FLG_HDL,
        NAM_CNE_SHP,
        COD_CNE_SHP,
        COD_AGT,
        NAM_AGT,
        COD_FLT_CAR,
        DAT_FLT,
        COD_FLT_NUM,
        COD_AWB_CHG,
        DAT_FRT_IN,
        TIM_FRT_IN,
      } = awbDetail;
      formAWB.setFieldsValue({
        queueNo: COD_QUE_NUM_CHK,
        awbFirst: MAWB.first,
        awbLast: MAWB.last,
        hawb: MAWB.hawb,
        impExp: FLG_IMP_EXP,
        org: COD_APT_BRD,
        des: COD_APT_OFF,
        pcs: PCS,
        weight: WGT,
        "re-weight": RE_WGT === 0 ? "" : RE_WGT,
        chargeWeight: CHG_WGT,
        "H/M": FLG_HDL,
        SHC1: SHC1,
        SHC2: SHC2,
        SHC3: SHC3,
        consigneeCode: COD_CNE_SHP,
        consigneeName: NAM_CNE_SHP,
        agentCode: COD_AGT,
        agentName: NAM_AGT,
        flightCarrier: COD_FLT_CAR,
        flightDate: moment(DAT_FLT).format("DD-MMM-YYYY"),
        flightNo: COD_FLT_NUM,
        awbCharge: COD_AWB_CHG,
        ata:
          moment(DAT_FRT_IN).format("DD-MMM-YYYY") +
          " " +
          convertNumberToTime(TIM_FRT_IN),
      });
    }
  };

  const nextTab = () => {
    if (!isInfoCreated)
      warning({
        title: "Thông báo",
        icon: <ExclamationCircleOutlined />,
        content: "Bạn cần phải đăng kí thông tin người nhận để tiếp tục!",
      });
    else props.setActiveKey("awb");
  };

  const submit = async (values) => {
    confirm({
      title: "Thông báo",
      icon: <ExclamationCircleOutlined />,
      content: "Bạn có đồng ý cập nhật thông tin hay không?",
      async onOk() {
        const {
          receiveDate,
          receiverId,
          receiverName,
          email,
          phone,
          address,
          impCat,
        } = values;
        const allowReceiveDayByRegis = 3;
        let validation = true;
        if (
          moment(receiveDate) >
          moment().add(allowReceiveDayByRegis, "days").endOf("day")
        ) {
          notification.warning({
            message: `Ngày đăng ký nhận hàng chỉ được chọn trong vòng ${allowReceiveDayByRegis} ngày kể từ ngày hiện tại!`,
            style: { marginRight: "-50%" },
          });
          validation = false;
        }
        if (moment(receiveDate) <= moment()) {
          notification.warning({
            message: "Không thể nhận hàng vào giờ được chọn!",
            style: { marginRight: "-50%" },
          });
          validation = false;
        }
        const count = email.toUpperCase().split(";");
        //max count
        if (count > 3) {
          notification.warning({
            style: { marginRight: "-50%" },
            message: `Tối đa 3 địa chỉ email!`,
          });
          validation = false;
        } else {
          const listEmail = email.toUpperCase().split(";", 3);
          listEmail.forEach((e) => {
            const validate = e.match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
            if (!validate) {
              notification.warning({
                style: { marginRight: "-50%" },
                message: `Email ${e} không hợp lệ!`,
              });
              validation = false;
            }
          });
        }
        /* if (
          moment(receiveDate).format("HH") < "08" ||
          moment(receiveDate).format("HH") > "18"
        ) {
          notification.warning({
            message: "Chỉ có thể nhận hàng từ 8:30 đến 17:30!",
            style: { marginRight: "-50%" },
          });
          return;
        }
        if (
          moment(receiveDate).format("HH") === "08" &&
          moment(receiveDate).format("mm") < "30"
        ) {
          notification.warning({
            message: "Chỉ có thể nhận hàng từ 8:30 đến 17:30!",
            style: { marginRight: "-50%" },
          });
          return;
        }
        if (
          moment(receiveDate).format("HH") === "17" &&
          moment(receiveDate).format("mm") >= "30"
        ) {
          notification.warning({
            message: "Chỉ có thể nhận hàng từ 8:30 đến 17:30!",
            style: { marginRight: "-50%" },
          });
          return;
        } */

        if (info === undefined && validation) {
          try {
            const chkInsertRes = await IMP_WEB_CHK_INS(
              {
                COD_AWB_PFX: MAWB.first,
                COD_AWB_NUM: MAWB.last,
                COD_AWB_SFX: MAWB.suffix,
                COD_HWB_NUM: MAWB.hawb,
                DAT_DLV: moment(receiveDate).format("LL HH:mm"),
                DLV_ODR_RCV_ID: receiverId.toUpperCase(),
                DLV_ODR_RCV_NAM: receiverName.toUpperCase(),
                EML_RCV_EINV: email.toUpperCase(),
                CUS_RMK: phone.toUpperCase() + "-" + address.toUpperCase(),
                COD_TYP_SVR: impCat,
              },
              token
            );
            if (chkInsertRes.data.TABLE_ID > 0)
              notification.success({
                message: "Tạo thông tin thành công!",
                style: { marginRight: "-50%" },
              });
            loadData();
          } catch (err) {
            if (err.response.status === 401) {
              notification.error({
                style: { marginRight: "-50%" },
                message: t("home.sessionNoti"),
              });

              dispatch(checkinUser({}));
              dispatch(setPaymentInfo(undefined));
              dispatch(userAwbLogout());
              localStorage.removeItem("userAWB");
              localStorage.removeItem("checkin");
              localStorage.removeItem("pInfo");
              history.push("/");
            } else
              notification.error({
                message: err.response.data.error.slice(
                  3,
                  err.response.data.error.length
                ),
                style: { marginRight: "-50%" },
              });
          }
        } else {
          try {
            if (info.COD_STS === "NY") {
              form.validateFields([
                "email",
                "receiverId",
                "receiverName",
                "phone",
                "receiveDate",
              ]);
              await IMP_WEB_CHK_UPD(
                {
                  ...info,
                  DLV_ODR_RCV_ID: receiverId.toUpperCase(),
                  DLV_ODR_RCV_NAM: receiverName.toUpperCase(),
                  DAT_DLV: moment(receiveDate).format("LL HH:mm"),
                  EML_RCV_EINV: email.toUpperCase(),
                  CUS_RMK: phone.toUpperCase() + "-" + address.toUpperCase(),
                  COD_TYP_SVR: impCat,
                },
                token
              );
              notification.success({
                message: "Cập nhật thông tin thành công!",
                style: { marginRight: "-50%" },
              });
              loadData();
            }
          } catch (error) {
            let errorStatus;
            if (err.response.status === 401) {
              notification.error({
                style: { marginRight: "-50%" },
                message: t("home.sessionNoti"),
              });

              dispatch(checkinUser({}));
              dispatch(setPaymentInfo(undefined));
              dispatch(userAwbLogout());
              localStorage.removeItem("userAWB");
              localStorage.removeItem("checkin");
              localStorage.removeItem("pInfo");
              history.push("/");
            } else if (!error.response) {
              errorStatus = "Error: Network Error";
            } else {
              if (error.response.data) {
                errorStatus = error.response.data.error.slice(
                  3,
                  error.response.data.error.length
                );
              }
            }
            notification.error({
              message: errorStatus,
              style: { marginRight: "-50%" },
            });
          }
        }
      },
    });
  };

  function disabledDate(current) {
    return (
      current < moment().startOf("day") /* || moment(current).day() === 0 */
    );
  }

  const handleQueue = () => {
    confirm({
      title: "Thông báo",
      icon: <ExclamationCircleOutlined />,
      content: "Bạn có đồng ý đăng ký thông tin hay không?",
      async onOk() {
        if (info.COD_STS === "NY") {
          try {
            const isCreatedCheckinObj = await IMP_WEB_CHK_CRE_CHK_OBJ(
              info,
              token
            );
            if (isCreatedCheckinObj) {
              notification.success({
                message: "Đăng kí thông tin thành công!",
                style: { marginRight: "-50%" },
              });
            }
          } catch (err) {
            if (err.response.status === 401) {
              notification.error({
                style: { marginRight: "-50%" },
                message: t("home.sessionNoti"),
              });

              dispatch(checkinUser({}));
              dispatch(setPaymentInfo(undefined));
              dispatch(userAwbLogout());
              localStorage.removeItem("userAWB");
              localStorage.removeItem("checkin");
              localStorage.removeItem("pInfo");
              history.push("/");
            } else
              notification.error({
                message: err.response.data.error,
                style: { marginRight: "-50%" },
              });
          } finally {
            setTimeout(() => loadData(), 500);
          }
        }
      },
    });
  };
  useEffect(() => {
    showData();
  }, [info, awbDetail]);
  return (
    <>
      {visible && (
        <ModalUpdateAgent
          loadData={loadData}
          visible={visible}
          setVisible={setVisible}
          info={info}
        />
      )}
      {visibleDatePicker && (
        <ModalDatePicker
          visible={visibleDatePicker}
          form={form}
          setVisible={setVisibleDatePicker}
          ata={moment(
            moment(awbDetail.DAT_FRT_IN).format("DD-MMM-YYYY") +
              " " +
              convertNumberToTime(awbDetail.TIM_FRT_IN)
          )}
        />
      )}
      <Row>
        <Col span={1}>
          <h2>AWB</h2>
        </Col>
        <Col style={{ display: "flex", justifyContent: "flex-end" }} span={23}>
          <Form.Item style={{ marginRight: "5px" }}>
            <Button
              className="btn-payment"
              style={{ width: "fit-content" }}
              onClick={() => form.submit()}
              disabled={isInfoCreated || status === "XX"}
            >
              {/* {t("header.submit")} */} Cập nhật
            </Button>
          </Form.Item>
          <Form.Item style={{ marginRight: "5px" }}>
            <Button
              className="btn-payment"
              style={{ width: "fit-content" }}
              onClick={handleQueue}
              disabled={isInfoCreated || status === "XX"}
            >
              {/* {t("header.submit")} */}Đăng ký
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              className="btn-payment"
              style={{ width: "fit-content" }}
              onClick={nextTab}
            >
              {/* {t("header.submit")} */} Tiếp tục
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Form
          className="awb-payment"
          layout="vertical"
          onFinish={submit}
          form={formAWB}
          autoComplete="off"
        >
          <Row gutter={[5, 0]}>
            <Col span={4} md={2} lg={1}>
              <Form.Item name="awbFirst" label="AWB No">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>
            <Col span={6} md={3} lg={2}>
              <Form.Item label=" " name="awbLast">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>
            <Col span={3} md={2} lg={1}>
              <Form.Item name="H/M" label="H/M">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>
            <Col span={9} md={4} lg={3}>
              <Form.Item name="hawb" label="HWB Number">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>
            <Col span={6} md={2} lg={1}>
              <Form.Item name="impExp" label="IMP/EXP">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>

            <Col span={5} md={2} lg={1}>
              <Form.Item name="org" label="Org">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>

            <Col span={5} md={2} lg={1}>
              <Form.Item name="des" label="Des">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>

            <Col span={6} md={2} lg={1}>
              <Form.Item name="pcs" label="Pieces">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>

            <Col span={5} md={2}>
              <Form.Item name="weight" label="Weight">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>

            <Col span={5} md={2}>
              <Form.Item name="re-weight" label="Re-Weight">
                <Input style={{ textTransform: "uppercase" }} readOnly />
              </Form.Item>
            </Col>

            <Col span={5} md={2}>
              <Form.Item name="chargeWeight" label="Charge weight">
                <Input style={{ textTransform: "uppercase" }} readOnly />
              </Form.Item>
            </Col>

            <Col span={5} md={2} lg={1}>
              <Form.Item name="flightCarrier" label="Flight No">
                <Input style={{ textTransform: "uppercase" }} readOnly />
              </Form.Item>
            </Col>
            <Col span={7} md={2}>
              <Form.Item label=" " name="flightNo">
                <Input style={{ textTransform: "uppercase" }} readOnly />
              </Form.Item>
            </Col>
            <Col span={6} md={3} lg={2}>
              <Form.Item label="Flight Date" name="flightDate">
                <Input style={{ textTransform: "uppercase" }} readOnly />
              </Form.Item>
            </Col>
            <Col span={5} md={5} lg={3}>
              <Form.Item name="ata" label="ATA">
                <Input style={{ textTransform: "uppercase" }} readOnly />
              </Form.Item>
            </Col>
            <Col span={5} md={2} lg={1}>
              <Form.Item name="awbCharge" label="Charge">
                <Input style={{ textTransform: "uppercase" }} readOnly />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <hr />
      <Form form={formAWB} layout="vertical" className="awb-payment">
        <Row>
          <Col span={24} md={12}>
            <h2>Tên người gửi/ nhận hàng</h2>
            <Row gutter={[5, 0]}>
              <Col span={6} md={4}>
                <Form.Item name="consigneeCode" label="Code">
                  <Input style={{ textTransform: "uppercase" }} readOnly />
                </Form.Item>
              </Col>
              <Col span={18} md={12}>
                <Form.Item name="consigneeName" label="Name">
                  <Input style={{ textTransform: "uppercase" }} readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24} md={12}>
            <h2>Công ty được uỷ quyền nhận hàng</h2>
            <Row gutter={[5, 0]}>
              <Col span={6} md={4}>
                <Form.Item name="agentCode" label="Code">
                  <Input style={{ textTransform: "uppercase" }} readOnly />
                </Form.Item>
              </Col>
              <Col span={18} md={12}>
                <Form.Item name="agentName" label="Name">
                  <Input style={{ textTransform: "uppercase" }} readOnly />
                </Form.Item>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "4px",
                }}
                span={6}
              >
                <Button
                  className="btn-payment"
                  style={{ width: "fit-content" }}
                  onClick={() => setVisible(true)}
                  disabled={isInfoCreated}
                >
                  {/* {t("header.submit")} */} Cập nhật
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <hr />
      <h2>Thông tin người nhận hàng</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Form
          className="form-receiver"
          requiredMark={false}
          layout="vertical"
          onFinish={submit}
          form={form}
          autoComplete="off"
        >
          <Row gutter={[5, 0]}>
            <Col span={23} md={5}>
              <Form.Item
                name="receiveDate"
                label={"Ngày nhận"}
                rules={[
                  {
                    required: true,
                    message: /* t("header.msgComfirm") */ "",
                  },
                ]}
              >
                <DatePicker
                  dropdownClassName="hide-footer"
                  disabled
                  disabledDate={disabledDate}
                  showTime={{ defaultValue: moment().add(30, "minutes") }}
                  onSelect={(val) => {
                    form.setFieldsValue({ receiveDate: val });
                  }}
                  format={"DD MMM YYYY HH:mm"}
                  style={{ background: "#FFF" }}
                />
              </Form.Item>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
              span={2}
            >
              <Form.Item style={{ marginBottom: "0px" }}>
                <Button
                  disabled={status !== "NY"}
                  className="btn-payment"
                  onClick={() => setVisibleDatePicker(true)}
                >
                  Chọn
                </Button>
              </Form.Item>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
              span={2}
            >
              <Form.Item
                name="impCat"
                label={/* t("header.newPassConfirm") */ "Mức PV nhanh"}
              >
                <Input readOnly style={{ width: "60%" }} />
              </Form.Item>
            </Col>
            <Col span={24} md={5}>
              <Form.Item
                name="receiverId"
                label={/* t("header.newPassConfirm") */ "CCCD/ CMND người nhận"}
                rules={[
                  {
                    required: true,
                    message: /* t("header.msgComfirm") */ "",
                  },
                ]}
              >
                <Input
                  readOnly={status !== "NY"}
                  style={{ textTransform: "uppercase" }}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={5}>
              <Form.Item
                name="receiverName"
                label={/* t("header.username") */ "Họ và tên người nhận"}
                rules={[
                  {
                    required: true,
                    message: /* t("header.msgUsername") */ "",
                  },
                ]}
              >
                <Input
                  readOnly={status !== "NY"}
                  style={{ textTransform: "uppercase" }}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={5}>
              <Form.Item
                name="phone"
                label={/* t("header.oldPass") */ "Số điện thoại người nhận"}
                rules={[
                  {
                    required: true,
                    message: /* t("header.msgComfirm") */ "",
                  },
                ]}
              >
                <Input
                  readOnly={status !== "NY"}
                  style={{ textTransform: "uppercase" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[5, 0]}>
            <Col span={24} md={12}>
              <Form.Item
                name="address"
                label={/* t("header.oldPass") */ "Địa chỉ người nhận"}
              >
                <Input
                  style={{ textTransform: "uppercase" }}
                  readOnly={status !== "NY"}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="email"
                label={/* t("header.newPass") */ "Email người nhận"}
                rules={[
                  {
                    required: true,
                    message: /* t("header.msgNewPass") */ "",
                  },
                ]}
              >
                <Input
                  readOnly={status !== "NY"}
                  style={{ textTransform: "uppercase" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
export default TabReceiverInfo;
