import React, { useState, useEffect } from "react";
import { Button, notification, Table, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { saveReleaseObj } from "../../config/checkinApi";
import { loadReleaseObj } from "./../../config/checkinApi";
import moment from "moment";
import { useHistory } from "react-router";
import { checkinUser, userAwbLogout } from "../../actions/userAWB";
import { setPaymentInfo } from "../../actions/payment";

const ReleaseModal = ({
  visible,
  setVisible,
  dataList,
  loadData,
  params,
  isGetRelease,
}) => {
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const { dataLogin } = useSelector((state) => state.userAwb);
  const { t } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOk = async () => {
    if (selectedRows.length > 0) {
      setLoading(true);
      const releaseParams = {
        RLS_YN: "Y",
        RLS_STS: "OK",
        COD_STS: "OK",
      };
      const undoReleaseParams = {
        RLS_YN: "N",
        RLS_STS: "NY",
        COD_STS: "NY",
      };
      try {
        const saveObj = async (deliverReleaseObj) => {
          try {
            let _saveObj = {};
            isGetRelease
              ? (_saveObj = { ...deliverReleaseObj, ...releaseParams })
              : (_saveObj = { ...deliverReleaseObj, ...undoReleaseParams });
            await saveReleaseObj(_saveObj, dataLogin.token);
            return true;
          } catch (error) {
            if (error.response.status === 401) {
              notification.error({
                style: { marginRight: "-50%" },
                message: t("home.sessionNoti"),
              });

              dispatch(checkinUser({}));
              dispatch(setPaymentInfo(undefined));
              dispatch(userAwbLogout());
              localStorage.removeItem("userAWB");
              localStorage.removeItem("checkin");
              localStorage.removeItem("pInfo");
              history.push("/");
            }
            return false;
          }
        };
        const loadObj = async (tableId) => {
          try {
            const loadRes = await loadReleaseObj(tableId, dataLogin.token);
            if (loadRes.data.mCol[0]) return loadRes.data.mCol[0];
            else return false;
          } catch (error) {
            if (error.response.status === 401) {
              notification.error({
                style: { marginRight: "-50%" },
                message: t("home.sessionNoti"),
              });

              dispatch(checkinUser({}));
              dispatch(setPaymentInfo(undefined));
              dispatch(userAwbLogout());
              localStorage.removeItem("userAWB");
              localStorage.removeItem("checkin");
              localStorage.removeItem("pInfo");
              history.push("/");
            } else console.log(error);
            return false;
          }
        };
        const promiseArr = [];
        const saveObjAction = async (data) => {
          if (data.TABLE_ID > 0) {
            const releaseObj = await loadObj({ TABLE_ID: data.TABLE_ID });
            if (releaseObj) {
              await saveObj(releaseObj);
            }
          } else {
            await saveObj({
              TABLE_ID: data.TABLE_ID,
              COD_AWB_PFX: data.COD_AWB_PFX,
              COD_AWB_NUM: data.COD_AWB_NUM,
              COD_AWB_SFX: data.COD_AWB_SFX,
              COD_HWB_NUM: data.COD_HWB_NUM,
              COD_FLT_CAR: data.COD_FLT_CAR,
              COD_FLT_NUM: data.COD_FLT_NUM,
              DAT_FLT_ARR: data.DAT_FLT_ARR,
              QTY_PCS: data.QTY_PCS,
              QTY_GRS_WGT: data.QTY_GRS_WGT,
              USR_RLS: dataLogin.data.user_name,
              RLS_DAT: moment().format("LL"),
              RLS_DAT_STS: data.DAT_FLT_ARR,
              RLS_DAT_END: moment(new Date(data.DAT_FLT_ARR))
                .add(90, "days")
                .format("LL"),
              RLS_CMP_NAM: "NY",
              RLS_CMP_ADR: "NY",
              RLS_CMP_CTC: "NY",
              RLS_RMK: "NY",
              RLS_FIL_NAM: "NY",
              RLS_NO: "NY",
            });
          }
        };
        selectedRows.forEach((data) => {
          setLoading(true);
          promiseArr.push(saveObjAction(data));
        });
        await Promise.all(promiseArr);
        setLoading(false);
        loadData(params);
        isGetRelease
          ? notification.success({
            style: { marginRight: "-50%" },
            message: "Tạo thành công!",
          })
          : notification.success({
            style: { marginRight: "-50%" },
            message: "Hủy thành công!",
          });
        setVisibleConfirm(() => false);
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setTimeout(() => setVisible(false), 500);
      } catch (error) {
        // console.log(error);
      }
    } else {
      setVisibleConfirm(false);
      notification.warning({
        style: { marginRight: "-50%" },
        message: "Vui lòng chọn ít nhất 1 dòng!",
      });
    }
  };

  let columns = [
    {
      title: "No",
      dataIndex: "key",
      key: "key",
      className: "head_tb_repo",
    },
    {
      title: t("agentReport.fightNo"),
      dataIndex: "A03_FLIGHT_NO",
      key: "A03_FLIGHT_NO",
      className: "head_tb_repo",
    },
    {
      title: t("agentReport.Date"),
      dataIndex: "A04_FLIGHT_DATE",
      key: "A04_FLIGHT_DATE",
      className: "head_tb_repo",
    },
    {
      title: t("agentReport.MAWB"),
      dataIndex: "parent",
      key: "parent",
      className: "head_tb_repo",
      children: [
        {
          title: "MAWB",
          dataIndex: "A01_MAWB_NO",
          key: "A01_MAWB_NO",
          className: "head_tb_repo",
        },
        {
          title: "HAWB",
          dataIndex: "A02_HAWB_NO",
          key: "A02_HAWB_NO",
          className: "head_tb_repo",
        },
      ],
    },
    {
      title: t("agentReport.Document"),
      dataIndex: "parent",
      key: "parent",
      className: "head_tb_repo",
      children: [
        {
          title: t("agentReport.Pieces"),
          dataIndex: "A05_QTY_MAN_PCS",
          key: "A05_QTY_MAN_PCS",
          className: "head_tb_repo",
        },
        {
          title: t("agentReport.GrossWeight"),
          dataIndex: "A06_QTY_MAN_WEIGHT",
          key: "A06_QTY_MAN_WEIGHT",
          className: "head_tb_repo",
        },
      ],
    },
    {
      title: t("agentReport.Actual"),
      dataIndex: "parent",
      key: "parent",
      className: "head_tb_repo",
      children: [
        {
          title: t("agentReport.Pieces"),
          dataIndex: "QTY_PCS",
          key: "QTY_PCS",
          className: "head_tb_repo",
        },
        {
          title: t("agentReport.GrossWeight"),
          dataIndex: "QTY_GRS_WGT",
          key: "QTY_GRS_WGT",
          className: "head_tb_repo",
        },
        {
          title: t("Charge weight"),
          dataIndex: "A22_CHG_WGT",
          key: "A22_CHG_WGT",
          className: "head_tb_repo",
        },
      ],
    },
    {
      title: t("agentReport.CargoStatus"),
      dataIndex: "A09_IRR_INF",
      key: "A09_IRR_INF",
      className: "head_tb_repo",
    },
    {
      title: t("agentReport.Note"),
      dataIndex: "A10_SHP_STATUS",
      key: "A10_SHP_STATUS",
      className: "head_tb_repo",
    },
    {
      title: t("agentReport.Account"),
      dataIndex: "A12_WEB_ACC",
      key: "A12_WEB_ACC",
      className: "head_tb_repo",
    },
    {
      title: "DLV Rls ID",
      dataIndex: "TABLE_ID",
      key: "TABLE_ID",
      className: "head_tb_repo",
    },
    {
      title: "DLV Rls Status",
      dataIndex: "A21_RLS_STS",
      key: "A21_RLS_STS",
      className: "head_tb_repo",
    },
  ];

  const showData = async () => {
    setLoading(true);

    let data = [];
    dataList.map((dt, index) => {
      return data.push({
        key: ++index,
        A03_FLIGHT_NO: dt.A03_FLIGHT_NO,
        A04_FLIGHT_DATE: dt.A04_FLIGHT_DATE,
        A01_MAWB_NO: dt.A01_MAWB_NO,
        A02_HAWB_NO: dt.A02_HAWB_NO,
        A05_QTY_MAN_PCS: dt.A05_QTY_MAN_PCS,
        A06_QTY_MAN_WEIGHT: dt.A06_QTY_MAN_WEIGHT,
        QTY_PCS: dt.A07_QTY_RCV_PCS,
        QTY_GRS_WGT: dt.A08_QTY_RCV_WEIGHT,
        A09_IRR_INF: dt.A09_IRR_INF,
        A10_SHP_STATUS: dt.A10_SHP_STATUS,
        A12_WEB_ACC: dt.A12_WEB_ACC,
        COD_AWB_PFX: dt.A13_COD_AWB_PFX,
        COD_AWB_NUM: dt.A14_COD_AWB_NUM,
        COD_AWB_SFX: dt.A15_COD_AWB_SFX,
        COD_HWB_NUM: dt.A16_C_COD_HWB_NUM,
        COD_FLT_CAR: dt.A17_COD_FLT_CAR,
        COD_FLT_NUM: dt.A18_COD_FLT_NUM,
        DAT_FLT_ARR: dt.A19_DAT_FLT_ARR,
        TABLE_ID: dt.A20_RLS_ID,
        A21_RLS_STS: dt.A21_RLS_STS,
        A22_CHG_WGT: dt.A22_CHG_WGT
      });
    });
    setDataSource(data);
    setLoading(false);
  };
  useEffect(() => {
    if (visible === true) showData();
    // eslint-disable-next-line
  }, [visible]);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  return (
    <Modal
      width={"100%"}
      title="Release List"
      visible={visible}
      onCancel={() => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setVisible(false);
      }}
      footer={[
        <Button
          className="btn-payment"
          onClick={() => {
            setSelectedRowKeys([]);
            setSelectedRows([]);
            setVisible(false);
          }}
          style={{ width: "fit-content", marginLeft: "5px" }}
        >
          Cancel
        </Button>,
        <Button
          className="btn-payment"
          onClick={() => setVisibleConfirm(true)}
          style={{ width: "fit-content" }}
        >
          Ok
        </Button>,
      ]}
    >
      <Table
        bordered
        loading={loading}
        className="table-payment"
        columns={columns}
        pagination={{ position: "bottomRight", hideOnSinglePage: true }}
        rowSelection={{
          selectedRowKeys,
          fixed: true,
          onChange: onSelectChange,
        }}
        dataSource={dataSource}
      />
      <Modal
        centered
        width={"620px"}
        title={"Thông báo"}
        visible={visibleConfirm}
        onOk={() => setVisibleConfirm(false)}
        onCancel={() => setVisibleConfirm(false)}
        footer={[
          <Button onClick={() => setVisibleConfirm(false)}>Không</Button>,
          <Button onClick={handleOk} loading={loading} type="primary">
            Đồng ý
          </Button>,
        ]}
      >
        {isGetRelease
          ? `Bạn có muốn tạo tài khoản nhận hàng cho các lô hàng này ?`
          : `Bạn có muốn huỷ tài khoản nhận hàng cho các lô hàng này ?`}
      </Modal>
    </Modal>
  );
};

export default ReleaseModal;
