/* eslint-disable */
import React from "react";
import moment from "moment";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  DatePicker,
  Input,
  notification,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { setMAWB } from "../actions/MAWB";
import ModalAddNew from "../components/checkout/hawb/ModalAddNew";
import { AWB_MST_LoadByAgent } from "../config/apiConfig";
import { useHistory } from "react-router";
import { checkinUser, userAwbLogout } from "../actions/userAWB";
import { setPaymentInfo } from "../actions/payment";
const { RangePicker } = DatePicker;

const TabHawbFee = () => {
  const { dataLogin } = useSelector((state) => state.userAwb);
  const [userCode, setUserCode] = useState();
  const [dateRange, setDateRange] = useState();
  const [rowId, setRowId] = useState(0);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState();
  const [dataSource, setDataSource] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("common");
  const today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

  const [form] = useForm();
  const dateFormat = "DD-MM-YYYY";
  const columns = [
    {
      title: "",
      dataIndex: "key",
      key: "key",
      width: "5%",
    },
    {
      title: "AWB",
      dataIndex: "COD_AWB_PFX",
      key: "COD_AWB_PFX",
      colSpan: 2,
      width: "5%",
    },
    {
      title: "AWB",
      dataIndex: "COD_AWB_NUM",
      key: "COD_AWB_NUM",
      colSpan: 0,
      width: "10%",
    },
    {
      title: "ORG",
      dataIndex: "COD_ORI",
      key: "COD_ORI",
      width: "5%",
    },
    {
      title: "DES",
      dataIndex: "COD_FDS",
      key: "COD_FDS",
      width: "5%",
    },
    {
      title: "NOG",
      dataIndex: "DES_AWB_NOG",
      key: "DES_AWB_NOG",
      width: "20%",
    },
    {
      title: "PCS",
      dataIndex: "QTY_TOT_PCS",
      key: "QTY_TOT_PCS",
      width: "5%",
    },
    {
      title: "Weight",
      dataIndex: "QTY_GRS_WGT",
      key: "QTY_GRS_WGT",
      width: "5%",
    },
    {
      title: "Charge weight",
      dataIndex: "FWB_WGT",
      key: "FWB_WGT",
      width: "5%",
    },
    {
      title: "Code charge",
      dataIndex: "COD_AWB_CHG",
      key: "COD_AWB_CHG",
      width: "10%",
    },
    {
      title: "HDL",
      dataIndex: "FLG_HDL",
      key: "FLG_HDL",
      width: "5%",
    },
    {
      title: "Flight",
      dataIndex: "COD_AWB_DOC_ARR_CAR",
      key: "COD_AWB_DOC_ARR_CAR",
      width: "5%",
      colSpan: 2,
    },
    {
      title: "Flight",
      dataIndex: "COD_AWB_DOC_ARR_FLT",
      key: "COD_AWB_DOC_ARR_FLT",
      width: "5%",
      colSpan: 0,
    },
    {
      title: "Flight Date",
      dataIndex: "DAT_AWB_DOC_ARR",
      key: "DAT_AWB_DOC_ARR",
      width: "10%",
    },
  ];

  const submit = async (values) => {
    const { usercode, dateRange } = values;
    dateRange && setDateRange(dateRange);
    usercode ? setUserCode(usercode) : setUserCode(dataLogin.data.user_code);
    if (moment(dateRange[1]).diff(moment(dateRange[0]) + 1, "days") > 30) {
      notification.warning({
        message: "Ngày bắt đầu và ngày kết thúc không quá 1 tháng",
        style: { marginRight: "-50%" },
      });
      return;
    }
    try {
      setLoading(true);
      const queryString = `${usercode ?? dataLogin.data.user_code}%23${moment(
        dateRange[0]
      ).format("DDMMMYYYY")}%23${moment(dateRange[1]).format("DDMMMYYYY")}%23`;
      let data = [];
      const loadByAgentRes = await AWB_MST_LoadByAgent(
        queryString,
        dataLogin.token
      );
      if (loadByAgentRes.data.mCol.length > 0) {
        loadByAgentRes.data.mCol.map((dt, index) => {
          data.push({
            key: ++index,
            COD_AWB_PFX: dt.COD_AWB_PFX,
            COD_AWB_NUM: dt.COD_AWB_NUM,
            COD_ORI: dt.COD_ORI,
            COD_FDS: dt.COD_FDS,
            DES_AWB_NOG: dt.DES_AWB_NOG,
            QTY_TOT_PCS: dt.QTY_TOT_PCS,
            QTY_GRS_WGT: dt.QTY_GRS_WGT,
            COD_AWB_CHG: dt.COD_AWB_CHG,
            FLG_HDL: dt.FLG_HDL,
            FLG_CAR: dt.FLG_CAR,
            FLG_NUM: dt.FLG_NUM,
            DAT_FLT: dt.DAT_FLT,
            COD_AWB_DOC_ARR_CAR: dt.COD_AWB_DOC_ARR_CAR,
            COD_AWB_DOC_ARR_FLT: dt.COD_AWB_DOC_ARR_FLT,
            DAT_AWB_DOC_ARR: moment(dt.DAT_AWB_DOC_ARR).format("DD-MM-YYYY"),
            FWB_WGT: dt.FWB_WGT
          });
        });
      } else {
        notification.error({
          message: "Không có dữ liệu!",
          style: { marginRight: "-50%" },
        });
      }
      setDataSource(data);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      } else
        notification.error({ message: "Lỗi!", style: { marginRight: "-50%" } });
    }
  };
  const clearMAWB = () => {
    dispatch(setMAWB({}));
    localStorage.removeItem("MAWB");
  };
  useEffect(() => {
    return clearMAWB();
  }, []);
  const handleRowSelect = (record) => {
    return {
      onClick: () => {
        setRowId(record.key);
        dispatch(
          setMAWB({
            first: record.COD_AWB_PFX,
            last: record.COD_AWB_NUM,
            suffix: "P",
            hawb: "",
          })
        );
        localStorage.setItem(
          "MAWB",
          JSON.stringify({
            first: record.COD_AWB_PFX,
            last: record.COD_AWB_NUM,
            suffix: "P",
            hawb: "",
          })
        );
        setVisible(true);
      },
    };
  };

  const tailLayout = { labelCol: { span: 20 }, wrapperCol: { span: 16 } };
  return (
    <div className="hawb-fee-content">
      <ModalAddNew
        usercode={userCode}
        dateRange={dateRange}
        fetchAwb={submit}
        setVisible={setVisible}
        visible={visible}
      />
      <Row className="awb-tab-title-wrapper">
        <Col span={24} className="awb-tab-title">
          <h5>{t("header.hawbFee")}</h5>
        </Col>
      </Row>
      <Row className="awb-tab-content">
        <Row className="control-wrapper">
          <Col span={24}>
            <Form
              className="form-hawb-fee"
              onFinish={submit}
              layout={"inline"}
              initialValues={{
                dateRange: [
                  moment(today, dateFormat).subtract(1, "months"),
                  moment(today, dateFormat),
                ],
                awbNo: "*",
              }}
              form={form}
              autoComplete="off"
            >
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
                gutter={[5, 0]}
              >
                {dataLogin.data.user_code === "admi" && (
                  <Col span={24} md={6}>
                    <Form.Item
                      {...tailLayout}
                      name="usercode"
                      label={t("header.agency")}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                )}
                <Col span={24} md={10}>
                  <Form.Item label={t("header.dateReport")} name="dateRange">
                    <RangePicker format={dateFormat} />
                  </Form.Item>
                </Col>
                <Col
                  style={{ display: "flex", justifyContent: "center" }}
                  span={24}
                  md={3}
                >
                  <Form.Item>
                    <Button
                      style={{ width: "fit-content" }}
                      htmlType={"submit"}
                    >
                      {t("header.click")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Row>
      <Row className="result-wrapper">
        <Col span={24}>
          <Table
            loading={loading}
            pagination={false}
            className="table-payment"
            columns={columns}
            dataSource={dataSource}
            onRow={handleRowSelect}
            rowClassName={(record) => {
              return record.key === rowId ? "row-selected" : "";
            }}
          />
        </Col>
      </Row>
    </div>
  );
};
export default TabHawbFee;
