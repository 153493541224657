/* eslint-disable */
import React, { useRef, useState } from "react";
import moment from "moment";
import { GetReportAgent } from "../../config/webTrackApi";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Skeleton,
  Table,
  DatePicker,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setMAWB } from "../../actions/MAWB";
import Controls from "./Controls";
import ReleaseModal from "./ReleaseModal";
import { checkinUser, userAwbLogout } from "../../actions/userAWB";
import { setPaymentInfo } from "../../actions/payment";
import { useHistory } from "react-router";
const { RangePicker } = DatePicker;
const Report = ({ setActiveKey, handleRenderLetter }) => {
  const history = useHistory();
  const [tableDataDocs, setTableDataDocs] = useState();
  const [tableDataSHP, setTableDataSHP] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [params, setParams] = useState();
  const [dataRelease, setDataRelease] = useState([]);
  const [dataUndo, setDataUndo] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isGetRelease, setIsGetRelease] = useState(true);
  const { dataLogin } = useSelector((state) => state.userAwb);
  const today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate() +
      " " +
      today.getHours() +
      ":" +
      today.getMinutes();
  const { t } = useTranslation("common");
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const renderTableSHP = (header, dataSource, footer) => {
    let columns = [
      {
        title: "No",
        dataIndex: "index",
        key: "index",
        className: "head_tb_repo",
      },
      {
        title: t("agentReport.fightNo"),
        dataIndex: "A03_FLIGHT_NO",
        key: "A03_FLIGHT_NO",
        className: "head_tb_repo",
      },
      {
        title: t("agentReport.Date"),
        dataIndex: "A04_FLIGHT_DATE",
        key: "A04_FLIGHT_DATE",
        className: "head_tb_repo",
      },
      {
        title: t("agentReport.MAWB"),
        dataIndex: "parent",
        key: "parent",
        className: "head_tb_repo",
        children: [
          {
            title: "MAWB",
            dataIndex: "A01_MAWB_NO",
            key: "A01_MAWB_NO",
            className: "head_tb_repo",
          },
          {
            title: "HAWB",
            dataIndex: "A02_HAWB_NO",
            key: "A02_HAWB_NO",
            className: "head_tb_repo",
          },
        ],
      },
      {
        title: t("agentReport.Document"),
        dataIndex: "parent",
        key: "parent",
        className: "head_tb_repo",
        children: [
          {
            title: t("agentReport.Pieces"),
            dataIndex: "A05_QTY_MAN_PCS",
            key: "A05_QTY_MAN_PCS",
            className: "head_tb_repo",
          },
          {
            title: t("agentReport.GrossWeight"),
            dataIndex: "A06_QTY_MAN_WEIGHT",
            key: "A06_QTY_MAN_WEIGHT",
            className: "head_tb_repo",
          },
        ],
      },
      {
        title: t("agentReport.Actual"),
        dataIndex: "parent",
        key: "parent",
        className: "head_tb_repo",
        children: [
          {
            title: t("agentReport.Pieces"),
            dataIndex: "A07_QTY_RCV_PCS",
            key: "A07_QTY_RCV_PCS",
            className: "head_tb_repo",
          },
          {
            title: t("agentReport.GrossWeight"),
            dataIndex: "A08_QTY_RCV_WEIGHT",
            key: "A08_QTY_RCV_WEIGHT",
            className: "head_tb_repo",
          },
          {
            title: t("Charge weight"),
            dataIndex: "A22_CHG_WGT",
            key: "A22_CHG_WGT",
            className: "head_tb_repo",
          },
        ],
      },
      {
        title: t("agentReport.CargoStatus"),
        dataIndex: "A09_IRR_INF",
        key: "A09_IRR_INF",
        className: "head_tb_repo",
      },
      {
        title: t("agentReport.Note"),
        dataIndex: "A10_SHP_STATUS",
        key: "A10_SHP_STATUS",
        className: "head_tb_repo",
      },
      {
        title: t("agentReport.Account"),
        dataIndex: "A12_WEB_ACC",
        key: "A12_WEB_ACC",
        className: "head_tb_repo",
      },
      {
        title: "DLV Rls ID",
        dataIndex: "A20_RLS_ID",
        key: "A20_RLS_ID",
        className: "head_tb_repo",
      },
      {
        title: "DLV Rls Status",
        dataIndex: "A21_RLS_STS",
        key: "A21_RLS_STS",
        className: "head_tb_repo",
      },
    ];

    let date =
      today.getDate() +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getFullYear() +
      " " +
      today.getHours() +
      ":" +
      today.getMinutes() +
      ":" +
      today.getSeconds();

    setTableDataSHP(
      <Table
        className="table-bill"
        dataSource={dataSource}
        columns={columns}
        bordered
        scroll={{ x: 700 }}
        title={() => header}
        pagination={{
          position: "bottomRight",
          hideOnSinglePage: true,
          defaultPageSize: 1000,
        }}
        summary={() => (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={14}>
                {t("agentReport.Time")} {date}
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2}>
                {t("agentReport.Note")}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={0} colSpan={12}>
                {footer.note}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )}
      />
    );
  };
  const submitReport = (value) => {
    try {
      setLoading(true);
      setParams(value);
      let dataSourceDocs = [];
      let dataSourceSHP = [];
      let _dataRelease = [];
      let _dataUndo = [];
      let footerDocs = "";
      let start_date =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();
      let end_date =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();
      let code = "*";
      let user_code;
      if (value.awbNo !== undefined) {
        code = value.awbNo;
      }

      if (value.user_code !== undefined) {
        user_code = value.user_code;
      }

      if (value.dateReport !== undefined) {
        start_date = value.dateReport[0].format("DD/MM/YYYY");
        end_date = value.dateReport[1].format("DD/MM/YYYY");
      }

      let urlDocs = "api/GET_AGENT_IMP_STATUS_DOC";
      let urlSHP = "api/GET_AGENT_IMP_STATUS_SHP";
      let data = {
        STR_CONNECT: "sss",
        START_DATE: start_date,
        END_DATE: end_date,
        FLIGHT_NO: "",
        AWB_NO: code,
        AGENT_CODE:
          dataLogin.data.user_code === "admi"
            ? user_code
            : dataLogin.data.user_code,
      };
      let header = t("home.agent") + ": " + data.AGENT_CODE;
      GetReportAgent(urlDocs, urlSHP, data, dataLogin.token)
        .then((res) => {
          let MAWB = 0;
          let HAWB = 0;
          if (
            res.data &&
            res.status === 200 &&
            res.data.DOC &&
            res.data.DOC.mCol.length > 0
          ) {
            res.data.DOC.mCol.map((data, index) => {
              if (data.A03_MAWB_NO === data.A04_MAWB_NO_DISP) {
                MAWB = MAWB + 1;
              } else {
                HAWB = HAWB + 1;
              }
              dataSourceDocs.push({
                index: ++index,
                A01_FLIGHT_NO: data.A01_FLIGHT_NO,
                A02_FLIGHT_DATE: data.A02_FLIGHT_DATE,
                A04_MAWB_NO_DISP: data.A04_MAWB_NO_DISP,
                A05_HAWB_NO: data.A05_HAWB_NO,
                A09_DOC_ATA: data.A09_DOC_ATA,
                A06_HDL_INF: data.A06_HDL_INF,
                A07_IRR_INF: data.A07_IRR_INF,
              });
            });
            footerDocs = "Tổng cộng : MAWB: " + MAWB + " HAWB: " + HAWB;
            renderTableDocs(header, footerDocs, dataSourceDocs);
          } else {
            renderTableDocs(header, footerDocs, dataSourceDocs);
          }

          if (
            res.data &&
            res.status === 200 &&
            res.data.SHP &&
            res.data.SHP.mCol.length > 0 &&
            res.data.SHP.mCol !== null
          ) {
            res.data.SHP.mCol.map((data, index) => {
              if (
                ["CHECKIN", "READY"].includes(data.A10_SHP_STATUS) &&
                (data.A20_RLS_ID === 0 ||
                  (data.A20_RLS_ID > 0 && data.A21_RLS_STS === "NY"))
              ) {
                _dataRelease.push({ ...data });
              }
              if (
                ["CHECKIN", "READY"].includes(data.A10_SHP_STATUS) &&
                data.A20_RLS_ID > 0 &&
                data.A21_RLS_STS === "OK"
              ) {
                _dataUndo.push({ ...data });
              }
              dataSourceSHP.push({
                index: ++index,
                A03_FLIGHT_NO: data.A03_FLIGHT_NO,
                A04_FLIGHT_DATE: data.A04_FLIGHT_DATE,
                A01_MAWB_NO: (
                  <a
                    onClick={() => {
                      setActiveKey("import-info");
                      dispatch(
                        setMAWB({
                          first: data.A01_MAWB_NO.slice(0, 3),
                          last: data.A01_MAWB_NO.slice(3, 11),
                          isSubmitForm: true,
                        })
                      );
                      handleRenderLetter(data.A02_HAWB_NO, data.A01_MAWB_NO);
                    }}
                    className="mawb-link"
                  >
                    {data.A01_MAWB_NO}
                  </a>
                ),
                A02_HAWB_NO: (
                  <a
                    onClick={() =>
                      handleRenderLetter(data.A02_HAWB_NO, data.A01_MAWB_NO)
                    }
                    className="hawb-link"
                  >
                    {data.A02_HAWB_NO}
                  </a>
                ),
                A05_QTY_MAN_PCS: data.A05_QTY_MAN_PCS,
                A06_QTY_MAN_WEIGHT: data.A06_QTY_MAN_WEIGHT,
                A07_QTY_RCV_PCS: data.A07_QTY_RCV_PCS,
                A08_QTY_RCV_WEIGHT: data.A08_QTY_RCV_WEIGHT,
                A09_IRR_INF: data.A09_IRR_INF,
                A10_SHP_STATUS: data.A10_SHP_STATUS,
                A12_WEB_ACC: data.A12_WEB_ACC,
                A20_RLS_ID: data.A20_RLS_ID,
                A21_RLS_STS: data.A21_RLS_STS,
                A22_CHG_WGT: data.A22_CHG_WGT
              });
              setDataRelease(_dataRelease);
              setDataUndo(_dataUndo);
              renderTableSHP(header, dataSourceSHP, {
                note:
                  data.A12_WEB_ACC === "NY/NY"
                    ? /* "Lô hàng chưa đủ điều kiện làm thủ tục online" */ ""
                    : "",
              });
            });
          } else {
            renderTableSHP(header, dataSourceSHP, {
              note:
                data.A12_WEB_ACC === "NY/NY"
                  ? /* "Lô hàng chưa đủ điều kiện làm thủ tục online" */ ""
                  : "",
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            notification.error({
              style: { marginRight: "-50%" },
              message: t("home.sessionNoti"),
            });

            dispatch(checkinUser({}));
            dispatch(setPaymentInfo(undefined));
            dispatch(userAwbLogout());
            localStorage.removeItem("userAWB");
            localStorage.removeItem("checkin");
            localStorage.removeItem("pInfo");
            history.push("/");
          }
        });
    } catch (error) {
      if (error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      }
    }
  };

  const renderTableDocs = (header, footer, dataSource) => {
    let columns = [
      {
        title: "No",
        dataIndex: "index",
        key: "index",
        className: "head_tb_repo",
      },
      {
        title: t("agentReport.fightNo"),
        dataIndex: "A01_FLIGHT_NO",
        key: "A01_FLIGHT_NO",
        className: "head_tb_repo",
      },
      {
        title: t("agentReport.Date"),
        dataIndex: "A02_FLIGHT_DATE",
        key: "A02_FLIGHT_DATE",
        className: "head_tb_repo",
      },
      {
        title: t("agentReport.MAWB"),
        dataIndex: "A04_MAWB_NO_DISP",
        key: "A04_MAWB_NO_DISP",
        className: "head_tb_repo",
        render: (text, rec) => (
          <div className={rec.A05_HAWB_NO > 0 && "text-bold"}>{text}</div>
        ),
      },
      {
        title: t("agentReport.TotalHAWB"),
        dataIndex: "A05_HAWB_NO",
        key: "A05_HAWB_NO",
        className: "head_tb_repo",
      },
      {
        title: t("agentReport.DocumentHAWB"),
        dataIndex: "A09_DOC_ATA",
        key: "A09_DOC_ATA",
        className: "head_tb_repo",
      },
      {
        title: t("agentReport.ShipmentInformation"),
        dataIndex: "A06_HDL_INF",
        key: "A06_HDL_INF",
        className: "head_tb_repo",
      },
      {
        title: t("agentReport.IrregularInformation"),
        dataIndex: "A07_IRR_INF",
        key: "A07_IRR_INF",
        className: "head_tb_repo",
      },
    ];

    setTableDataDocs(
      <Table
        className="table-bill"
        dataSource={dataSource}
        columns={columns}
        bordered
        scroll={{ x: 700 }}
        title={() => header}
        pagination={{
          position: "bottomRight",
          hideOnSinglePage: true,
          defaultPageSize: 1000,
        }}
        footer={() => footer}
      />
    );
  };
  const [form] = useForm();
  const tailLayout = {
    wrapperCol: { offset: 1, span: 16 },
  };
  const dateFormat = "DD-MM-YYYY";

  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  return (
    <div>
      <ReleaseModal
        loadData={submitReport}
        visible={visible}
        params={params}
        setVisible={setVisible}
        dataList={isGetRelease ? dataRelease : dataUndo}
        isGetRelease={isGetRelease}
      />
      <Row className="awb-tab-title-wrapper">
        <Col span={24} className="awb-tab-title">
          <h5>{t("header.title")}</h5>
        </Col>
      </Row>
      <Row className="awb-tab-content">
        <Row className="control-wrapper">
          <Col span={24} className="control">
            <Form
              className="form-report"
              onFinish={submitReport}
              layout={"inline"}
              initialValues={{
                dateReport: [
                  moment(today, dateFormat),
                  moment(today, dateFormat),
                ],
                awbNo: "*",
              }}
              form={form}
              autoComplete="off"
            >
              {dataLogin.data.user_code === "admi" && (
                <Form.Item
                  {...tailLayout}
                  name="user_code"
                  label={t("header.agency")}
                  rules={[
                    {
                      required: true,
                      message: t("agentReport.requiredUserCode"),
                    },
                  ]}
                >
                  <Input className="flight-number" />
                </Form.Item>
              )}
              <Form.Item
                {...tailLayout}
                name="awbNo"
                label={t("header.billReport")}
              >
                <Input className="flight-number" />
              </Form.Item>
              <Form.Item
                style={{ marginLeft: "35px" }}
                wrapperCol={{ offset: 1, span: 18 }}
                label={t("header.dateReport")}
                name="dateReport"
              >
                <RangePicker
                  disabledDate={disabledDate}
                  format={dateFormat}
                  className="flight-date"
                />
              </Form.Item>
              <Form.Item>
                <Button style={{ width: "fit-content" }} htmlType={"submit"}>
                  {t("header.click")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button style={{ width: "fit-content" }} onClick={handlePrint}>
                  {t("header.print")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col style={{ marginTop: "5px" }}>{t("header.inputReport")}</Col>
        </Row>
        <Row ref={componentRef} className="result-wrapper">
          <Row className="result-title-wrapper">
            <Col span={24} className="result-title">
              <h5>{t("header.nofiSheet")}</h5>
              <Skeleton loading={loading}>{tableDataDocs}</Skeleton>
            </Col>
          </Row>
          <Row className="result-content-wrapper">
            <Col span={24} className="result-content">
              <h5>{t("header.nofiComing")}</h5>
              <Skeleton loading={loading}>{tableDataSHP}</Skeleton>
            </Col>
          </Row>
        </Row>
        {tableDataDocs && (
          <Row style={{ width: "100%" }}>
            <Col
              span={24}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Controls
                setIsGetRelease={setIsGetRelease}
                setVisible={setVisible}
              />
            </Col>
          </Row>
        )}
      </Row>
    </div>
  );
};
export default Report;
